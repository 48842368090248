import axios from 'axios'
import CONFIG from "./Config"

const apis = {
    "apiSinos": axios.create(
        {
            baseURL: CONFIG.baseUrl,
            withCredentials: false,
            headers: {
                accept: "application/json",
                "Content-type": "application/json"
            }
        }
    ),
    "apiVFace": axios.create(
        {
            baseURL: CONFIG.urlVFace,
            withCredentials: false,
            headers: {
                accept: "application/json",
                "Content-type": "application/json"
            }
        }
    )
}

export default apis;

