<template>
<!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
            <div class="container px-5">
                <router-link to="/" class="navbar-brand">Sinos da Catedral</router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><router-link to="/login" class="nav-link">Inscreva-se</router-link></li>
                        <li class="nav-item"><router-link to="/login" class="nav-link">Logar</router-link></li>
                    </ul>
                </div>
            </div>
        </nav>


  <!--<processosvue :lotes="lotes"/> -->
  <router-view @atualizacao="onRefresh" @fotos-carregadas="onFinish" 
  @submissao="onSubmit"/>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
//import processosvue from '@/components/Processos.vue'
export default {
  
  components: {
  //  processosvue,
  },
  data() {
    return {
      lotes: new Array(),
    }
  },
  created() {
    this.$store.dispatch("fetchFotografos")
    this.$store.dispatch("fetchCameras")
    
    
    
  },
  methods: {
    onRefresh(lotes) {
      console.log("onRefresh")
      this.lotes.push(lotes);
    },
    onSubmit(lote) {
      console.log("onSubmit")
      this.lotes.push(lote);
      
    },
    onFinish(lote) {
      console.log("onFinish")
      let temp = this.lotes
    for( let i = 0; i < temp.length; i++){ 
        console.log(temp[i].id+"  "+lote.id)
        if ( temp[i].id == lote.id) { 
    
            temp.splice(i, 1); 
        }
    
    }
      this.lotes = temp
    },
  }
}
</script>



<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #a76a01;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
