import { createStore } from "vuex";
import Services from "../services/Services";
export default createStore({
  events: ['indentificar'],
  state: {
    fotografos: [],
    cameras: [],
    processos: [],
    cliente: null,
    lotes: [],
    fotos: [],
    fotosOutras: [],
    identificacao: 0
  },
  mutations: {
    SET_IDENTIFICACAO(state, value) {
      console.log("SET_IDENTIFICACAO", value);
      console.log(state.identificacao)
      state.identificacao = value;
      console.log(state.identificacao)
    },
    SET_CLIENTE(state, cliente) {
      state.cliente = cliente
    },
    SET_FOTOGRAFOS(state, fotografos) {
      state.fotografos = fotografos
    },
    SET_CAMERAS(state, cameras) {
      state.cameras = cameras
    },
    SET_PROCESSOS(state, processos) {
      state.processos = processos
    },
    PUT_LOTE(state, lote) {
      state.lotes.push(lote)
    },
    SET_FOTOS(state, fotos) {
      state.fotos = fotos
    },
    PUT_FOTO(state, foto) {
      state.fotos.push(foto)
    },
    SET_FOTOS_OUTRAS(state, fotos) {
      state.fotosOutras = fotos
    },
    PUT_FOTOS_OUTRAS(state, foto) {
      let incluir = true
      state.fotos.forEach((f)=>{if (f.id == foto.id) incluir = false})
      
      if (incluir) {
        state.fotosOutras.push(foto)
      }
      
    },
    PUT_PROCESSO(state, processo) {
      state.processos.push(processo)
    },
    REMOVE_PROCESSO(state, processo) {
      let index = -1
      state.processos.forEach((p, i)=>{if (p.id == processo.id) index = i})
      state.processos.splice(index, 1)
    }
  },
  actions: {
    fetchCliente(context, idVeroface) {
      Services.getCliente(idVeroface).then(
        (response)=> {
          context.commit("SET_CLIENTE", response.data)
          this.dispatch("fetchFotos", response.data.id)
        }
      )
      return this
    },
    //ver depois async/wait
    fetchFotografos(context) {
      Services.getFotografos().then(
        (response)=> {
          context.commit("SET_FOTOGRAFOS", response.data)
        }
      ).catch((e)=>{console.error("falha na carga dos fotografos.\n"+e)})
    },
    //ver depois async/wait
    fetchCameras(context) {
      Services.getCameras().then(
        (response)=> {
          console.log(response.data)
          context.commit("SET_CAMERAS", response.data)
        }
      ).catch((e)=>{console.error("falha na carga =das cameras.\n"+e)})
    },
    addLote(context, lote) {
      context.commit('PUT_LOTE',lote)
    },
    addProcesso(context, processo) {
      context.commit('PUT_PROCESSO',processo)
    },
    removeProcesso(context, processo) {
      context.commit('REMOVE_PROCESSO', processo)
    },
    fetchFotos(context, idIndividuo) {
      Services.getFotos(idIndividuo, null).then(
        (response)=> {
            context.commit("SET_FOTOS_OUTRAS", new Array())
            context.commit("SET_FOTOS", response.data)
            Services.carregarFotos()
          
        }
      )
    },
    fetchFotosOutras(context, idLote) {
      Services.getFotos(null, idLote).then(
        (response)=> {
          response.data.forEach( (f)=> context.commit("PUT_FOTOS_OUTRAS", f))
          Services.carregarFotosOutras()
          }
        
      )
      
    },
    identificacao(context, sucesso){
      if(sucesso){
        context.commit("SET_IDENTIFICACAO", 1)
      } else {
        context.commit("SET_IDENTIFICACAO", -1)
      }
    }, 
    
    buscaIdentificacao(context){
      context.commit("SET_IDENTIFICACAO", 99)
    },
    resetIdentificar(context){
      context.commit("SET_IDENTIFICACAO", 0)
    }
  },
  getters: {
    getCliente: (state)=>{state.cliente},
    getFotografos: (state) => {state.fotografos},
    getCameras: (state)=>{state.cameras},
    getFotos: (state) => {state.fotos},
    getFotosOutras: (state)=> {state.fotosOutras},
    getProcessos: (state)=>{state.processos},
    getIdentificacao: (state)=>{state.identificacao}
  },
  modules: {},
});
