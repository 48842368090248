<template>
<form action="http://localhost:8081/canela/upload" 
method="post" 
enctype="multipart/form-data"
@submit.prevent="onSubmit">
  Selecione as fotos para enviar:<br/>
  <input type="file" name="files" id="filesToUpload" multiple required>
  <br/>
  Informe o fotógrafo:
  <select name="fotografo" id="fotografo" required>
    <option value="null"></option>
    <option v-for="fot in fotografos" v-bind:key="fot.id"  v-bind:value="fot.id">
       {{ fot.nome }}
    </option>
  </select>
  <br/>

  Informe a câmera:
  <select name="camera" id="camera" required>
    <option value="null"></option>
    <option v-for="cam in cameras" v-bind:key="cam.id"  v-bind:value="cam.id">
       {{ cam.descricao }}
    </option>
  </select>
  <br/><br/>
  <input type="button" value="Enviar Fotos" v-on:click="onSubmit">
</form>  
</template>

<script>
import store from '../store'
import Services from '../services/Services';
export default {
  name: "enviarFotos",
  emits: ['atualizacao', 'fotos-carregadas', 'submissao'],
  computed: { 
    fotografos:()=>store.state.fotografos,
    cameras: ()=>store.state.cameras
  },
  methods: {
        onSubmit() {
          let form = document.querySelector("form");
          const formData = new FormData(form);
          console.log(form.files.files.length)
          Services.getLote(form.fotografo.value, form.camera.value, form.files.files.length)
          .then((resposta)=>{
            console.log(resposta)
            let lote = resposta.data
              store.dispatch('addProcesso', lote)
              this.$emit("submissao", lote)
              Services.submitFotos(formData, lote)
              .then((res) => {
                console.log(this)
                store.dispatch('removeProcesso', lote)
                this.$emit("fotos-carregadas", lote)
                console.log(lote)
                console.log(res)
                console.log("carregado")

              })
              .catch((err) => {
                console.log(err);
              })  
          })
          
        }    
    }     
}
</script>

<style>

</style>