import apis from "./ApiClient";
import router from "../router/index"
import store from "../store/index";

export default{
    getCameras() {
        return apis.apiSinos.get('/cameras/');
    },
    getFotografos() {
        return apis.apiSinos.get('/fotografos/');
    },
    getFotografo(id) {
        return apis.apiSinos.get('/fotografos/?id='+id)
    },
    getFotos(idIndividuo, idLote) {
        let params = ""
        if (idIndividuo != null) {
            params = "?id="+idIndividuo
        }
        if (idLote != null) {
            params += (params != "" ? "&":"?")+"idLote="+idLote 
        }
        return apis.apiSinos.get('/fotos/'+params)
    },
    carregarFotos() {
        let lts = new Array();
        store.state.fotos.forEach((f)=>{
            if (lts.indexOf(f.idLote)<0) {
                lts.push(f.idLote)
                store.dispatch("fetchFotosOutras", f.idLote)
            }
            let img = new Image();
            img.src = "http://localhost:8081/canela/foto?id="+f.id
            img.height = "150"
            let div = document.createElement("div")
            div.classList.add("col-md-3")
            div.classList.add("col-sm-6")
            div.appendChild(img)

            document.getElementById("divFotos").appendChild(div)
        })
        

    },
    carregarFotosOutras() {
        document.getElementById("divFotosOutras").innerHTML=""
        store.state.fotosOutras.forEach((f)=>{
            let img = new Image();
            img.src = "http://localhost:8081/canela/foto?id="+f.id
            img.height = "150"
            let div = document.createElement("div")
            div.classList.add("col-md-3")
            div.classList.add("col-sm-6")
            div.appendChild(img)
            document.getElementById("divFotosOutras").appendChild(div)
        })
        

    },
    getCliente(id) {
        return apis.apiSinos.get('/individuo/?idVeroface='+id)
    },
    logout() {
        router.push('/');
    },

    identificar(idRequisicao, foto) {
        let tempo = new Date()
        store.dispatch('buscaIdentificacao')
    
        
        let json =  {
                    "idRequisicao": idRequisicao, 
                    "retornaFoto": true,
                    "foto": foto
                    }
                    const headers = { 
                        "api_key": "7af669754ab141508ad41b2667f3536c"
                    };
                    apis.apiVFace.post("/core/reconhecer", json, {headers}).then((ret) =>{
                        document.getElementById("result").innerHTML = "duração: "+((new Date()).getTime() - tempo.getTime())+"ms\n" +
                                                                "sucesso: "+ret.data.sucesso+"\n"+
                                                                  "id: "+ret.data.id+"\n"+
                                                                  "precisão: "+ret.data.precisao
                        document.getElementById("fotoRetorno").src = "data:image/jpg;base64,"+ret.data.foto
                        console.log("duração: "+((new Date()).getTime() - tempo.getTime())+"ms\n" +
                        "sucesso: "+ret.data.sucesso+"\n"+
                          "id: "+ret.data.id+"\n"+
                          "precisão: "+ret.data.precisao)
                        if (ret.data.sucesso) {
                            store.dispatch("fetchCliente", ret.data.id)
                        } 
                        document.getElementById("clickPhotoBtn").disabled = false
                        
                        store.dispatch("identificacao", ret.data.sucesso)
                })
                
    },
    getLote(idFotografo, idCamera, qtdFotos) {
        console.log("http://localhost:8081/canela/newLote?"+
        "idFotografo="+idFotografo+ "&idCamera="+idCamera+"&qtdFotos="+qtdFotos)
        return apis.apiSinos.get("http://localhost:8081/canela/newLote?"+
            "idFotografo="+idFotografo+ "&idCamera="+idCamera+"&qtdFotos="+qtdFotos)
    },
    submitFotos(formData, lote) {
       return apis.apiSinos
      .post("http://localhost:8081/canela/upload?lote="+lote.id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    }
    
}
