<template>
<header class="masthead text-center text-white">
     <div v-show="buscando">
      
    <img id="searching"  src="../assets/buscando-duck.gif" width="240" style="border-radius: 50%;" />
    </div>
    <div v-show="inSucesso">
      
    <img  src="../assets/crying-duck.gif" width="240" style="border-radius: 50%;" />
    <br/>
    Ups! não achamos nenhuma foto para você.<br/>
    <button  @click="voltar" class="btn btn-success btn-xl rounded-pill mt-5">voltar</button>

    </div>
    <div id="operacional" class="masthead-content"  v-show="pronto">
     
    <div>
    <video id="video" width="320" height="240" style="border-radius: 50%" autoplay></video>
    <img id="searching" v-show="buscando" src="../assets/buscando-duck.gif" width="240" style="border-radius: 50%; display: none;" />
    </div>
    <div>Olhe para a camera<br/>e clique em "Capturar"<br/>
    </div>
    <div>
    <button id="clickPhotoBtn" @click="photoClick" class="btn btn-primary btn-xl rounded-pill mt-5">Capturar</button>
    </div>
    <div>
    <button id="start-camera" @click="cameraOnClick" class="btn btn-outline-warning btn-xs ">Clique aqui se a sua câmera não iniciar</button>
   </div>
   <div id="imagens" style="display: none;">
    <canvas id="foto"  width="320" height="240"></canvas><br/>
    <img id="fotoDestino"   width="320" height="240"/><br/>
    <img id="fotoRetorno"   width="320" height="240"/><br/>
    <textarea id="result"></textarea>
    </div>
</div>
<div>
<button v-show="sucesso" @click="voltar" class="btn btn-success btn-xl rounded-pill mt-5">voltar</button>
</div>
<div v-show="sucesso">
Achamos estas fotos:<br/>
<div class="container">
    <div id="divFotos" class="row" >

    </div>  
</div>
    <div>
        E também temos outras Fotos dos mesmos lotes:
        <div class="container">
    <div id="divFotosOutras"  class="row" >
    </div>
    </div>
    </div>
    </div> 
    </header>
     
</template>

<script>
import Services from '../services/Services';
import store from '../store/index.js';
export default {
    data() {
        return {
            video : Object,
            canvas: Object,
            fotoDestino: Object,
            fotoRetorno: Object,
            divFotos: Object,
            identificacao: Object
            }
        },
    created(){
         
         
    },
    mounted() {
        this.video = document.querySelector("#video");
        this.canvas = document.querySelector("#foto");
        this.fotoDestino = document.getElementById("fotoDestino")
        this.divFotos = document.getElementById("divFotos")
        this.clickPhotoBtn = document.getElementById("clickPhotoBtn")
        //this.searching = document.getElementById("searching")
        this.cameraOnClick()
        store.dispatch("resetIdentificar")
        
        this.identificacao = store.state.identificacao
        //this.identificacao = "12313131"
        
        
/*
camera_button.addEventListener('click', async function() {
  console.log("onclick")
   	let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
	video.srcObject = stream;
});

click_button.addEventListener('click', async function() {
   	canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
   	let image_data_url = canvas.toDataURL('image/jpeg');
    document.getElementById("fotoDestino").src = image_data_url;
    document.getElementById("divFotos").innerHTML = "";

   	// data url of the image
   	console.log(image_data_url);
       //individuo(4205)

       
    identificar(-1).then((ret)=>{
        if (ret.sucesso) {
            console.log("identificação com sucesso")
            console.log(ret.id);
            
            individuo(ret.id).then((indiv)=> {
                console.log("retorno: "+indiv.id)
            fotosIndividuo(indiv.id).then((fotos)=>{
                fotos.forEach((f)=>{
                    img = new Image();
                    img.src = "http://localhost:8081/canela/foto?id="+f.id
                    img.height = "150"
                    document.getElementById("divFotos").appendChild(img)
                })
            })
        })
        }
        console.log("depois "+ret);
        })
           });  




  })
  */
  },
  methods: {
    async cameraOnClick() {
      //let video = document.querySelector("#video");
   	  let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
	  this.video.srcObject = stream;
     
    },
    voltar() {
        store.dispatch("resetIdentificar")
        },
    photoClick() {
        //this.clickPhotoBtn.style.display = "none";
        this.clickPhotoBtn.disabled = true
        //this.searching.style.display = "inline-block"
        //this.video.style.display = "none"

        //document.getElementById("imagens").style.display = "in-line";
        this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
   	    let image_data_url = this.canvas.toDataURL('image/jpeg');
        this.fotoDestino.src = image_data_url;
        image_data_url = image_data_url.substring(document.getElementById("fotoDestino").src.indexOf(',')+1)
        this.divFotos.innerHTML = "";
        Services.identificar(1, image_data_url)
    }
  
    },
    computed: {
        pronto() {
            return store.state.identificacao == 0
        },
        buscando() {
            console.log("buscando: "+store.state.identificacao)
            return store.state.identificacao == 99
        },
        sucesso() {
            return store.state.identificacao == 1
        },
        inSucesso() {
            return store.state.identificacao == -1
        }
    }

}
</script>

<style>

</style>