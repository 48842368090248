<template>
  <div class="home">
    
    <HomePage msg="Bem Vindo ao Sinos da Catedral Fotos" @atualizacao="onAtualizacao" 
    @fotos-carregadas="onFotosCarregadas"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from "@/components/HomePage.vue";

export default {
  name: "Home",

  emits: ['atualizacao', 'fotos-carregadas', 'submissao'],
  
  components: {
    HomePage,
  },
  methods: {
    onAtualizacao(lote) {
      console.log("onAtualizacao")
      this.$emit("atualizacao", lote);
    },
    onFotosCarregadas(lote) {
      console.log("onFotosCarregadas")
      this.$emit("fotos-carregadas", lote);
    },
  }
}
</script>
